<template>
  <v-container >
    <v-card width="500" height="auto" class="mx-auto pa-1">
      <v-layout style="height: auto;">
        <v-form style="width: 100% ">
          <v-card class="mx-auto pa-2" style="margin-left: 5px " outlined elevation="3">
            <div>{{preUser_ID}}</div>
            <v-text-field dense outlined style="height: 45px; margin: 5px"
              :loading = "loadingName"
              id="name" 
              ref="name" 
              label="성명✽" 
              v-model="name" 
            />
            <v-text-field dense outlined style="height: 50px; margin: 5px"
              id="phone" 
              ref="phone" 
              label="연락처✽" 
              placeholder="숫자만 입력"
              v-model="phone"
              @keyup="getPhoneMask(phone)"
            />
            <div style="height: 0px; margin-left: 16px;  margin-top: -5px">알림수신✽</div>
            <v-radio-group dense outlined row 
              id="call_YN" 
              v-model="call_YN"
            >
                <v-radio ref="call_y" style="margin-left: 16px;" label="동의" value="Y" color="green" ></v-radio>
                <v-radio label="거부" value="N" color="red"></v-radio>
            </v-radio-group>
            <v-combobox dense outlined class="ma-1" style="height: 45px;" 
              id="duty" 
              ref="duty"
              label="직책✽" 
              placeholder="항목이 없으면 직접 입력하세요"
              :items="['대표','이사','본부장','농장/사업장장','부장','과장','대리','사원','기타']"
              :menu-props="{ top: false, offsetY: true }"
              v-model="duty"
              clearable 
            />
            <v-layout style="height: 45px; margin-top: -10px; margin: 5px; ">
              <v-text-field dense outlined style="height: 45px; margin-top: 0px; " 
                id="email_id" 
                ref="email_id" 
                label="이메일" 
                v-model="email_id" 
              />
              @
              <v-combobox dense outlined style="height: 45px; margin-top: 12px margin-right:50px" 
                id="email_host" 
                ref="email_host" 
                :items="['gmail.com','hotmail.com','naver.com','daum.net',]"
                :menu-props="{ top: false, offsetY: true }"
                placeholder="항목이 없으면 직접 입력하세요"
                v-model="email_host" 
              />
            </v-layout>
            <v-card-actions class="d-block" >
              <v-row justify= "center" >
                <v-spacer></v-spacer>
                <v-btn  v-if="this.$getters.userName !== '방문고객'" class="rounded-pill elevation-7 normal-btn" style="height: 30px; width: 100px; margin: 10px;" 
                  :loading="loading"
                  id="save" 
                  ref="save" 
                  type="submit" 
                  @click.prevent="save()"
                >
                  저장
                </v-btn>
                <v-btn class="rounded-pill elevation-7 new-btn" style="height: 30px; width: 100px; margin: 10px;" 
                  id="cancel" 
                  ref="cancel" 
                  @click="cancel()">
                  취소
                </v-btn>
                <v-spacer ></v-spacer>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>    
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import Common from "@/utils/custom/common.js";
import VueCookies from "vue-cookies";

export default {
  name: 'ChangeMyInfo',

  data: () => ({
    preUser_ID: "",
    name: "",
    phone: "",
    call_YN: "",
    duty: "",
    email_id: "",
    email_host: "",
    loadingName: false,
    loading: false,
  }),

  mounted() {
    this.accountInfo();
    this.$refs.name.focus();
  },

  methods: {
    clearData() {
      this.preUser_ID = "";
      this.name = "";
      this.phone = "";
      this.call_YN = "";
      this.duty = "";
      this.email_id = "";
      this.email_host = "";
      this.loadingName = false;
      this.loadingSave = false;
    }, 
    checkData() {
      if (!this.name.trim()) {
        this.$refs.name.focus();
        return "성명을 입력하세요";
      }
      if (!this.phone.trim()) {
        this.$refs.phone.focus();
        return "연락처를 입력하세요";
      }
      if (!this.call_YN) {
        this.$refs.call_y.$el.children[1].focus()
        return "알림 동의 여부를 선택하세요";
      }
      if (!this.duty) {
        this.$refs.duty.focus();
        return "직책을 선택 입력하세요";
      }
      return "";
    },
    accountInfo() {             // todo 개인 조회시 관할 농장/사업장리스트, 입사일 항목 추가
      this.$store.commit("resMessage","");
      this.loadingName = true;
      this.clearData();
      Apis.infoUser({
        account: VueCookies.get("account"), 
        },(res) => {  
          if (res.result) {
            // console.log("DATA",res.data);
            this.preUser_ID = res.data.account,
            this.name = res.data.user_name; 
            this.duty = res.data.duty;	
            let email = res.data.email1.split("@",2);
            this.email_id = email[0];
            this.email_host = email[1];
            this.phone = Common.getMask(res.data.phone1);
            this.call_YN = res.data.call_yn; 
            this.loadingNmae = false;
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            this.loadingName = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            this.loadingName = false;
            alert(err);
        }
      ); 
    },
    save() {
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      Apis.updateUserFree({
        account: this.preUser_ID,
        user_name : this.name.trim(),
        duty:	this.duty,
        email1:	this.email_id.trim() + "@" + this.email_host,
        phone1:	this.phone.replace(/[^0-9]/g, ''),
        call_yn: this.call_YN,
        },(res) => {  
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.loadingSave = false;
          } else {
            this.loadingSave = false;
            this.$refs.user_ID.focus();
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log(" updateUserFree API 호출 오류",err)
            alert(err);
        }
      )
     
    },
    cancel() {
      this.$router.go(-1);
    },

    getPhoneMask(val) {
      this.phone = Common.getMask(val)
    },

  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" >
</style>
<style lang="scss" scoped>
.normal-btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.confirm_btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
