<template>
  <v-container>
    <div class="login">
      <v-form>
        <v-card pa-1 width="500" height="auto"  class="elevation-7 mx-auto text-center mt-1 pa-2 rounded-lg">
          <v-card-text>
            <v-text-field outlined rounded
              class="mx-auto mt-5 "
              style="height: 45px; width: 100%; " 
              prepend-inner-icon="mdi-lock" 
              :type="showPrePassword ? 'text' : 'password'" 
              :append-icon="showPrePassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="현재 비밀번호"
              ref="cpassword" 
              v-model="cpassword"
              @click:append="showPrePassword = !showPrePassword"
              clearable
            />
            <v-text-field outlined rounded
              class="mx-auto mt-5 "
              style="height: 45px; width: 100%; " 
              prepend-inner-icon="mdi-lock" 
              :type="showNewPassword ? 'text' : 'password'" 
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="새 비밀번호"
              ref="npassword" 
              v-model="npassword"
              @click:append="showNewPassword = !showNewPassword"
              clearable
            />
            <v-text-field outlined rounded
              class="mx-auto mt-5 "
              style="height: 45px; width: 100%; " 
              prepend-inner-icon="mdi-lock" 
              :type="showConfirmPassword ? 'text' : 'password'" 
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="비밀번호 확인"
              ref="rpassword" 
              v-model="rpassword"
              @click:append="showConfirmPassword = !showConfirmPassword"
              clearable
            />
          </v-card-text>

        <v-card-actions class="justify-center d-block">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn v-if="this.$getters.userName !== '방문고객'" depressed width="100px" height="30px" ma-2
              id="confirm_btn" 
              class="elevation-7 rounded-pill normal-btn mt-5"  
              type="submit" 
              @click.prevent="changePassword()">
              확인
            </v-btn>
            <v-btn depressed width="100px" height="30px" ma-2
              id="cancel_btn" 
              class="elevation-7 rounded-pill new-btn mt-5 ml-3"  
              @click="cancel()">
              취소
            </v-btn>
            <v-spacer></v-spacer>
          </v-layout>


          
        </v-card-actions>
      </v-card>
      </v-form>

    </div>

  </v-container>
</template>

<script>
  export default {
    name: 'ChangePW',

    data() {
      return {
        cpassword: "",
        npassword: "",
        rpassword: "",
        showPrePassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
      };
    },
    mounted() {
      this.$refs.cpassword.focus();
    },

    methods : {
      // ...mapMutations(['doLogin']),
      cancel() {
        this.$router.go(-1);
      },

      changePassword() {
        let cpw = this.cpassword.trim();
        let npw = this.npassword.trim();
        let rpw = this.rpassword.trim();
        if (!cpw) {
          alert("현재 비밀번호를 입력해주세요.");
          this.$refs.cpassword.focus();
          return;
        }
        if (!npw) {
          alert("새 비밀번호를 입력해주세요.");
          this.$refs.npassword.focus();
          return;
        }
        if (!rpw) {
          alert("새 비밀번호를 한번 더 입력해주세요.");
          this.$refs.rpassword.focus();
          return;
        }
        if ( npw !== rpw ) {
          alert("새 비밀번호와 확인이 다릅니다.");
          this.$refs.npassword.focus();
          return;
        }

        this.$dispatch("changePassword", {
          now_pw: cpw,
          new_pw: npw,
        }).then((res) => {
          if (res.result) {
            alert("비밀번호 변경 : " + res.message);
            this.$router.go(-1);
          } else {
            alert("Error Code : " + res.resultCode + "\n" + res.message);
            this.$refs.cpassword.focus();
            return;
          }
        });
      },
    }
  }
</script>


<style lang="scss" scoped>
.normal-btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.confirm_btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>