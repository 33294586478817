<template>
  <v-container>
      <v-tabs color="#3a4f3f" centered>
        <v-tab>
          <v-icon left large color="#3a4f3f">
            mdi-account-edit
          </v-icon>
          <span class="tab_text_green"> 인적사항 </span>
        </v-tab>
        <v-tab width="100px">
          <v-icon left large color="#88191a">
            mdi-account-key
          </v-icon>
          <span class="tab_text_red">비밀번호 </span>
        </v-tab>
  
        <v-tab-item>
            <v-container>
              <ChangeMyInfo />
            </v-container>
        </v-tab-item>
        <v-tab-item >
            <v-container>
              <ChangePW />
            </v-container>
        </v-tab-item>
      </v-tabs>

 </v-container>
</template>

<script>
import ChangePW from "./child/ChangePW.vue";
import ChangeMyInfo from "./child/ChangeMyInfo.vue";

export default {
  name: 'MyInfo',
  components: {
    ChangePW,
    ChangeMyInfo,
  },
  data () {
    return {
      tabs: null,
    }
  },
  created () {
    // console.log("info",this.$getters)
  },
}
</script>
<style lang="scss" scoped >
.tab_text_green{
  color:#3a4f3f;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
.tab_text_red{
  color:#88191a#88191a;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
</style>
